<template>
  <v-container fluid>
    <loading-bar v-model="loading"></loading-bar>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-select
          :items="BracketTypes"
          item-text="text"
          item-value="value"
          v-model="type"
          label="Bracket Type"
          color="color3"
          item-color="color3"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" v-if="!captureSeed">
        <v-autocomplete
          label="Number of Teams"
          v-model="teams"
          :items="Array.from({ length: 128 }, (v, i) => i + 1)"
          hide-details
          color="color3"
          item-color="color3"
        ></v-autocomplete>
      </v-col>
      <v-col cols="6" sm="3" v-if="captureSeed" :key="1">
        <v-text-field
          label="Number of Pools"
          type="number"
          v-model="pools"
          hide-details
          color="color3"
        ></v-text-field>
      </v-col>
      <v-col cols="6" sm="3" v-if="captureSeed" :key="2">
        <v-text-field
          label="Teams From Each Pool"
          v-model="n"
          hide-details
          color="color3"
        ></v-text-field>
      </v-col>

    </v-row>
    <v-row dense justify="end" style="min-height: 24px">
      <v-btn color="color3" text class="xsmall ma-0" v-if="type.startsWith('SINGLE')" @click.stop="captureSeed = !captureSeed">{{captureSeed ? 'Regular' : 'Capture Seed?'}}</v-btn>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <bracket-display v-if="bracket" :bracket="bracket" :demo="true" :key="`${keyCount}`"></bracket-display>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BracketTypes } from '@/classes/BracketTypes.js'
import BracketDisplay from '@/components/Tournament/Brackets/BracketDisplay.vue'
import Bracket from '@/classes/Bracket.js'
export default {
  data () {
    return {
      type: 'SINGLE_ELIM',
      teams: 16,
      bracket: null,
      loading: false,
      captureSeed: false,
      pools: 4,
      n: 3,
      keyCount: 0
    }
  },
  computed: {
    BracketTypes () {
      return BracketTypes
    }
  },
  methods: {
    getBracket () {
      this.loading = true
      const url = `/brackets/Sample?type=${this.type}&teamCount=${this.teams}&capture=${this.captureSeed}&pools=${this.pools}&n=${this.n}`
      this.$http.get(url)
        .then(r => {
          this.bracket = new Bracket(this.$http, r.data)
          this.keyCount++
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    type: 'getBracket',
    teams: 'getBracket',
    captureSeed: 'getBracket',
    pools: 'getBracket',
    n: 'getBracket'
  },
  components: {
    BracketDisplay
  },
  mounted () {
    this.getBracket()
  }
}
</script>

<style>

</style>
